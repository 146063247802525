











































import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import AppCardPhoto from '@/components/AppCardPhoto.vue';
import formatDateTime from '@/utils/formatDateTime';
import tagExpireTime from '@/utils/tagExpireTime';
import { WashTag, PhotoInfoFragment } from '@/types/schema';

export interface EnhancedWashTag extends WashTag {
  front?: PhotoInfoFragment | null;
  back?: PhotoInfoFragment | null;
}

export default Vue.extend({
  name: 'AppListItemWashTags',
  components: {
    AppListItem,
    AppCardPhoto,
  },
  inheritAttrs: false,
  props: {
    tags: {
      type: Array as () => EnhancedWashTag[],
      default: Array as () => EnhancedWashTag[],
    },
  },
  methods: {
    formatDateTime,
    tagExpireTime,
  },
});
