










































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppListItemWashTags from '@/components/AppListItemWashTags.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import TRAILERS from '@/graphql/queries/Trailers.graphql';
import TRAILER_DELETE from '@/graphql/mutations/TrailerDelete.graphql';
import { Trailer } from '@/types/schema';

export default Vue.extend({
  name: 'AdminTrailers',
  components: {
    AppMain,
    AppEmpty,
    AppListItemWashTags,
    AppPanel,
    AppPanelItem,
  },
  data() {
    return {
      trailers: [] as Trailer[],
    };
  },
  methods: {
    async deleteTrailer(id: Trailer['id']) {
      if (!confirm("Are you sure? This action can't be undone.")) return;

      try {
        await this.$apollo.mutate({
          mutation: TRAILER_DELETE,
          variables: {
            input: { id },
          },
        });
        this.$apollo.queries.trailers.refetch();
        this.$notify({
          text: 'Trailer deleted!',
          type: 'success',
          duration: 6000,
        });
      } catch (error) {
        this.$notify({
          text: error.graphQLErrors[0].message,
          type: 'error',
          duration: 6000,
        });
      }
    },
  },
  apollo: {
    trailers: {
      query: TRAILERS,
      fetchPolicy: 'cache-and-network',
    },
  },
});
